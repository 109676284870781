import React from "react";

import CheckIcon from '@mui/icons-material/Check';
import getEnvironement from "../../../environnement";

const GenerateContratPDF = ({ productData, soussignesData, gerentsData }) => {

  // Extract the duration in months from the items array
  const extractDurationInMonths = () => {
    // Check pack.items first
    const item = productData?.pack?.items?.find(item => item.includes("mois de domiciliation"));
    
    // Extract numeric value from item
    if (item) {
      const match = item.match(/\d+/); 
      return match ? parseInt(match[0], 10) : 0; 
    }
  
    // If no valid item found, check pack.offres
    const offer = productData?.pack?.offer;
    if (offer) {
      const match = offer.match(/\d+/); // Extract the numeric part
      if (match) {
        const value = parseInt(match[0], 10);
        // Convert "ans" to months if applicable
        return offer.includes("ans") ? value * 12 : value;
      }
    }
  
    return 0; // Default to 0 if nothing is found
  };
  
  const calculateEndDate = (startDate, monthsToAdd) => {
    const date = new Date(startDate);
    date.setMonth(date.getMonth() + monthsToAdd);
    return date.toISOString().split("T")[0]; // Return the date in YYYY-MM-DD format
  };

  const durationInMonths = extractDurationInMonths(); // Get the duration dynamically

  const formattedDate = productData?.created_at
    ? new Date(productData?.created_at).toISOString().split("T")[0]
    : "Date non spécifiée";

  const endDate = productData?.created_at
    ? calculateEndDate(productData?.created_at, durationInMonths)
    : "Date non spécifiée";



  return (

    <div className="pdf-container" style={{ fontFamily: "Arial, sans-serif", paddingRight : '20mm', paddingLeft:'20mm', paddingTop:'5mm', paddingBottom:'10mm', fontSize: "17px", }}>
      <div style={{ width: "100%", background: "white" }}>
        <div className="header">
          <div style={{ textAlign: "center", marginBottom: "20px" }}>
            <img style={{ width: '40%', display: 'inline-block' }} src={`${getEnvironement().BACKEND_URL}/storage/app/public/${productData?.siege_social?.logo}`} alt="" />
          </div>
          <h2 style={{  textAlign: "center", fontWeight: 'bold', textTransform: "uppercase", marginBottom: "20px" }}>
            <u>Contrat de Domiciliation</u>
          </h2>
          <div style={{ fontSize: '15px', textAlign: "center", marginBottom: "20px" }}>
            <p>
              Conclu conformément au décret N°2.20.950 du 26 juin 2021 relatif à
              l’application des articles 2.544 et 7.544 de la loi 15.95 portant
              code du commerce.
            </p>
          </div>
        </div>

        <div className="body pdf-content">

          <b>Le Domiciliataire</b>
          <p style={{ marginTop: '10px' }}>
            La société {productData?.siege_social?.name}, RC: {productData?.siege_social?.rc}, TP:{" "}
            {productData?.siege_social?.tp}, IF: {productData?.siege_social?.if},
            CNSS: {productData?.siege_social?.cnss}, ICE: {productData?.siege_social?.ice}.
            <br />
          </p>

          <p style={{ marginTop: '10px', marginBottom: '10px' }}> SIS AU : {productData?.siege_social?.address}. </p>


          <h3 style={{ marginTop: '10px', marginBottom: '10px', fontWeight: 400 }}>Représentée par :</h3>
          <p style={{ marginTop: '10px', marginBottom: '10px' }}>
            <b>{productData?.siege_social?.manager} </b>, né le <b>{productData?.siege_social?.date_birth_manager}</b> titulaire de la CIN N° <b>{productData?.siege_social?.cin_manager}</b>.
            Demeurant au : {productData?.siege_social?.address_manager}, en sa qualité de gérant de la société


          </p>

          <p style={{ marginTop: '10px', marginBottom: '10px' }}><b>Et le domicilié</b></p>

          <p style={{ marginTop: '10px', marginBottom: '10px', fontWeight: "bold" }}> La société : <strong>{productData?.denominationValide} , ICE : {productData?.ice}</strong></p>

          <h3 style={{ marginTop: '10px', marginBottom: '10px', fontWeight: 400 }}>Représentée par :</h3>



          {soussignesData.map((rep, index) => {
            if (rep.data?.type === "Personne physique") {
              return (
                <span key={index}>
                  <b> {rep.data?.nom} {rep.data?.prenom} </b>, né le <b>{rep.data?.dateBirth}</b>, titulaire de CIN N° <b>{rep.data?.cin}</b>,
                  demeurant au <b>{rep.data?.adresse}.</b>
                  <br />
                </span>
              );
            } else if (rep.data?.type === "Personne morale") {
              return (
                <span key={index}>
                  <b>{rep.data?.denomination} </b>, ICE N° <b>{rep.data.ice} </b>, RC N° <b>{rep.data.rc} </b>,
                  siège social au <b>{rep.data?.adresseSiege} </b>.
                  <br />
                </span>
              );
            }
          })}

          <p style={{ marginTop: '15px', marginBottom: '15px', fontWeight: "bold" }}>Il a été convenu ce qui suit :</p>

          <p>
            <h2 style={{ fontWeight: "bold" }}> <u>Article 1: Objet </u></h2>

            Le présent contrat est conclu pour la domiciliation du siège de la société <b>{productData?.denominationValide}</b>, titulaire de l’
            ICE N° <b>{productData?.ice}</b>, en application des dispositions de l’article 2.544 de la loi 15.95 portant code
            de commerce.
          </p>

          <p>
            <h2 style={{ marginTop: '15px', fontWeight: "bold" }}> <u>Article 2: Les Services </u> </h2>

            Le domiciliataire s’engage à permettre au domicilié, objet de l’article premier, de bénéficier des services suivants: <br />
            -Exploitation de l’adresse du domiciliataire comme adresse du siège social du domicilié ;<br />
            -Mise à sa disposition d’un local ou de locaux équipés de moyens de communication spécifiques aux bureaux ;<br />
            <div className="page-break"></div>

            -Mise à sa disposition de locaux équipés pour la tenue des registres et documents prévus dans les textes législatifs
            et réglementaires en vigueur et permettant la préservation des registres et documents ainsi que leur consultation
            et mise à jour ; <br />
            * Donner mandat au domiciliataire, qui l’accepte, de recevoir en son nom toutes notifications,<br />
            <b>*Le centre de domiciliation s’engage à conserver le courrier à destination du client pour une durée
              n’excédant pas 03 mois.</b> A l’expiration de ce délai il décline toute responsabilité par rapport à la perte ou
            l’égarement de ce courrier.
          </p>
          <p >
            <h2 style={{ marginTop: '15px', fontWeight: "bold" }}> <u>ARTICLE 3 : OBLIGATIONS DU DOMICILIATAIRE</u> </h2>
           <h4 style={{ fontWeight: "bold" }}>1-  Obligations de la personne physique ou morale domiciliataire, </h4>
            <b>Pendant toute la durée du contrat, le domiciliataire est tenu par les obligations suivantes :</b> <br />
            - Tenu d’un dossier relatif à chaque personne domiciliée contenant des documents permettant, pour les
            personnes physiques de connaitre leurs adresses personnelles, leurs numéros de téléphone et leurs numéros de
            pièce d’identité ainsi que leurs adresses électroniques ; <br />

            - Et concernant les personnes morales, des documents permettant de connaitre les adresses et les numéros de
            pièces d’identité de leurs gérants ainsi que leurs adresses électroniques. <br />

            Le dossier devra contenir aussi les documents relatifs à tous les locaux d’exploitation de l’activité de la société
            domiciliée, le lieu de conservation des documents comptables au cas où ceux-ci ne sont pas conservés chez le
            siege_social?. <br />

            - S’assurer que le domicilié est bien inscrit au registre du commerce dans un délai de 3 mois à compter de la date
            de conclusion du contrat de domiciliation, lorsque la domiciliation est obligatoire conformément aux textes
            législatifs et réglementaires en vigueur. <br />

            - La communication à l’administration des impôts, à la trésorerie générale du royaume et le cas échéant, à la
            direction des douanes de la liste des personnes domiciliées pendant l’année précédente et ceux avant le 31
            janvier de chaque année. <br />

            - L’information du secrétariat du greffe du tribunal compétent de l’administration des impôts, de la trésorerie
            générale du royaume et le cas échéant, la direction des douanes, de l’expiration de la durée du contrat de la
            domiciliation ou de sa rupture anticipée et dans un délai de 1 mois à partir de l’arrêt du contrat. <br />

            - Communication aux huissiers de justice des services de recouvrement des créances publiques, titulaire de titres
            exécutoires des informations leur permettant de se mettre en contact avec la personne domiciliée. <br />

            - Respect du secret des informations et des données relatives aux domiciliées. <br />
            <div className="page-break"></div>

            - Aviser l’administration des impôts, la trésorerie du royaume, le cas échéant l’administration des douanes, dans
            un délai n’excèdent pas 15 jours, à partir de la date de réception des plis recommandés adressés par les services
            fiscaux et qui n’auront pas pu être remis aux personnes domiciliées. <br />

            La prise de la responsabilité solidaire pour le paiement des impôts et taxes relatifs à l’activité exercée par le
            domicilié conformément au dernier paragraphe de l’article 4-544 de la loi numéro 15-95 portant code du
            commerce.
          </p>

          <p>
            <h2 style={{ fontWeight: "bold" }}>
              2-  Obligations de la personne physique ou morale domiciliée,
            </h2>
            <b>La personne domiciliée est tenue le long de la durée du contrat par les obligations suivantes : </b> <br />
            - l’utilisation effective et exclusive des locaux comme siège social de l’entreprise ou la société, ou si le siège de
            l’entreprise ou la société est situé à l’étranger, comme agence ou représentation ou branche ou toute
            établissement qui lui est rattaché quel que soit sa nature ; <br />
            - déclaration au domiciliataire, s’il s’agit d’une personne physique, de toute modification survenue dans son
            adresse personnelle ou son activité. S’il s’agit d’une personne morale la déclaration au domiciliataire de toute
            modification dans sa forme juridique, sa raison sociale et son objet ainsi que les noms et adresses des gérants et
            des personnes titulaires de procurations délivrées par le domicilié en vue de conclure en son nom avec le
            domiciliataire et lui délivrer tous les documents relatifs à cette procuration ; <br />
            - informer le secrétariat greffe du tribunal compétent ainsi que l’administration des impôts, la trésorerie générale
            du royaume et le cas échéant, l’administration des douanes, de l’arrêt de la domiciliation et ce dans un délai de
            1 mois à compter de la date d’expiration du contrat de domiciliation ou de sa rupture anticipée ; <br />
            - rappeler sa qualité de domicilié chez le domiciliataire dans toutes les factures, les correspondances, les bons de
            commande et les communiqués et tous les documents commerciaux destinés aux tiers ; <br />
            - livrer au domiciliataire tous les registres et documents prévus par les textes législatifs en vigueur et nécessaires
            à l’exécution de ces obligations ; <br />
            - informer le domiciliataire dans un délai de 10 jours à compter de la date du changement de toute modification
            dans le ou les lieux de stockage des marchandises importées ou destinées à l’export ; <br />
            - informer le domiciliataire de tout conflit potentiel ou de tout litige dont l’entreprise domiciliée serait partie
            prenante à cause de son activité commerciale; <br /> </p>

          <p>
            <h2 style={{ fontWeight: "bold" }}>3-  Dossier de domiciliation :</h2>

            -La personne domiciliée doit déposer chez le domiciliataire un dossier constitué des documents suivants :

            <br /><CheckIcon style={{ fontSize: '14px' }} /> Copie des documents d’identité du représentant légal de la société domiciliée ;
            <br /><CheckIcon style={{ fontSize: '14px' }} /> Copie d’une pièce prouvant l’adresse du représentant légal de la société ;
            <div className="page-break"></div>

            <br /><CheckIcon style={{ fontSize: '14px' }} /> Extrait du compte bancaire ou spécimen de chèque ;
            <br /><CheckIcon style={{ fontSize: '14px' }} /> Numéro de téléphone du représentant légal de la société domiciliée ;
            <br /><CheckIcon style={{ fontSize: '14px' }} /> Adresse de réexpédition du courrier :
            <br /><CheckIcon style={{ fontSize: '14px' }} /> Copie des statuts de la société domiciliée ;
            <br />

            Le domicilié s’engage à informer l’administration des impôts, la trésorerie générale du royaume et le cas échéant,
            l’’administration des douanes de toute modification intervenue sur l’une des pièces constitutives du dossier dans
            un délai de 15 jours, à partir de sa prise de connaissance de cette modification ainsi que de toute modification
            des lieux de stockage des marchandises importées ou destinées à l’export.
          </p>


          <p>
            <h2 style={{ marginTop: '15px', fontWeight: "bold" }}> <u>Article 4: DUREE DU CONTRAT </u> </h2>

            e présent contrat de domiciliation est conclu pour une durée de <b>{durationInMonths} MOIS</b>, qui commencera le <b> {formattedDate}</b>  et se
            terminera le <b>{endDate}.</b> <br />
            A l’expiration de la durée du contrat de domiciliation ou en cas de sa rupture anticipée, le domicilié et le
            domiciliataire s’engagent, conformément au paragraphe 1 et 2 de l’article 3 du présent contrat à informer le
            secrétariat greffe du tribunal compétent du lieu d’activité et l’administration des impôts, la trésorerie générale
            des impôts et le cas échéant, l’administration des douanes de l’arrêt de la domiciliation, dans un délai d’un mois
            à partir de la date de l’arrêt du contrat.
          </p>

          <p>
            <h2 style={{ marginTop: '15px', fontWeight: "bold" }}> <u>Article 5: Loyer </u> </h2>

            Le présent contrat de domiciliation est conclu moyennant un loyer mensuel fixé à <b>125 DHS/ MOIS</b>, payable par
            (espèce/cheque) le loyer fixé comprend tous les services de l’article 2 du présent contrat.
          </p>

          <p>
            <h2 style={{ marginTop: '15px', fontWeight: "bold" }}> <u>Article 6: Tribunaux Compétents</u> </h2>

            Tout litige né entre les deux parties lors de l’exécution du pèsent contrat sera soumis à la compétence du tribunal
            du lieu de domiciliation.         </p>

          <p style={{ marginTop: '15px', fontWeight: "bold" }}> <u>Article 7: Élection de Domicile</u> </p>
          <p>
            Les parties élisent domicile à leur siège social: </p>

          <p style={{ fontWeight: "bold" }}> Le Domiciliataire:  <b style={{ fontWeight: 700 }}> {productData?.siege_social?.name}</b> </p>

          <p><b style={{ fontWeight: 700 }}>Le Domicilié:</b> <span style={{ fontWeight: 400 }}>La société</span> <b style={{ fontWeight: 700 }}> {productData?.denominationValide}</b> </p>


          <p style={{ fontWeight: "bold" }}> <u>Article 8: Procuration Spéciale</u> </p>
          <p>
            {gerentsData.map((item, index) => {
              if (!item.autre) {
                return (
                  <span key={index}>
                    <b>{item.soussigne?.data?.nom} {item.soussigne?.data?.prenom}</b>, titulaire de CIN N° <b>{item.soussigne?.data?.cin}</b>
                    {index < gerentsData.length - 1 ? " et " : ""}
                  </span>
                );
              } else {
                return (
                  <span key={index}>
                    <b>{item.nom} {item.prenom}</b>, titulaire de CIN N° <b>{item.cin}</b>
                    {index < gerentsData.length - 1 ? " et " : ""}
                  </span>
                );
              }
            })}
            , agissant en qualité d’associés cogérants de la société «<b>{productData?.denominationValide}</b>» <span style={{ fontWeight: 700 }}> (ICE: {productData?.ice})</span>,
            donnent par la présente procuration au {productData?.siege_social?.name} pour la
            réception de toutes sortes de notifications en leur nom pendant la durée du
            contrat.
          </p>
        </div>
        <div className="footer pdf-content">


          <p style={{ marginTop: "20px", textAlign: 'center', fontWeight: 700 }}>
            À Casablanca, le {formattedDate}
          </p>

          <table
            style={{
              width: "100%",
              marginTop: "60px",
              textAlign: "center",
              borderCollapse: "collapse",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    width: "50%",
                  }}
                >
                  SIGNATURE DOMICILIATAIRE
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    width: "50%",

                  }}
                >
                  SIGNATURE DOMICILIE
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    height: "200px",
                    border: "1px solid black",
                  }}
                ></td>
                <td
                  style={{
                    border: "1px solid black",
                  }}
                ></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  );
};

export default GenerateContratPDF;
