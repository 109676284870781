import React from 'react';
import { IconButton, Drawer, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  CarteGrise, Cession, ChangementStatut, CreationAssociation, CreationAutoEntreprise, CreationEntreprise, CreationStatut,
  Dissolution,
  Domiciliation,
  Marketing,
  Comptabilite
} from '../products';
import app_logo from '../../assets/images/LOGO-LS@2x.png';


export default function DetailsProductDrawer({
  onClose,
  open,
  produit,
  idProduit,
  product_id,
  changeStatus,
  onClickDeleteStatus,
  onClickSaveDocument,
  onClickDeleteDocument,
  saveEnterpriseDetails

}) {
  const getContent = (typeProduct) => {
    switch (typeProduct) {
      case 'CS':
        return (
          <CreationStatut
            produit={produit}
            idProduit={idProduit}
            changeStatus={changeStatus}
            onClickDeleteStatus={onClickDeleteStatus}
            onClickSaveDocument={onClickSaveDocument}
            onClickDeleteDocument={onClickDeleteDocument}
          />);
      case 'CE':
        return (
          <CreationEntreprise
            produit={produit}
            idProduit={idProduit}
            changeStatus={changeStatus}
            onClickDeleteStatus={onClickDeleteStatus}
            onClickSaveDocument={onClickSaveDocument}
            onClickDeleteDocument={onClickDeleteDocument}
            saveEnterpriseDetails={saveEnterpriseDetails}
          />);
      case 'DO':
        return (
          <Domiciliation
            produit={produit}
            idProduit={idProduit}
            changeStatus={changeStatus}
            onClickDeleteStatus={onClickDeleteStatus}
            onClickSaveDocument={onClickSaveDocument}
            onClickDeleteDocument={onClickDeleteDocument}
          />);
      case 'MKT':
        return (
          <Marketing
            produit={produit}
            idProduit={idProduit}
            changeStatus={changeStatus}
            onClickDeleteStatus={onClickDeleteStatus}
            onClickSaveDocument={onClickSaveDocument}
            onClickDeleteDocument={onClickDeleteDocument}
          />);
      case 'CPT':
        return (
          <Comptabilite
            produit={produit}
            idProduit={idProduit}
            changeStatus={changeStatus}
            onClickDeleteStatus={onClickDeleteStatus}
            onClickSaveDocument={onClickSaveDocument}
            onClickDeleteDocument={onClickDeleteDocument}
          />);
      case 'CA':
        return (
          <CreationAssociation
            produit={produit}
            idProduit={idProduit}
            changeStatus={changeStatus}
            onClickDeleteStatus={onClickDeleteStatus}
            onClickSaveDocument={onClickSaveDocument}
            onClickDeleteDocument={onClickDeleteDocument}
          />);
      case 'CAE':
        return (
          <CreationAutoEntreprise
            produit={produit}
            idProduit={idProduit}
            changeStatus={changeStatus}
            onClickDeleteStatus={onClickDeleteStatus}
            onClickSaveDocument={onClickSaveDocument}
            onClickDeleteDocument={onClickDeleteDocument}
          />);
      case 'CG':
        return (
          <CarteGrise
            produit={produit}
            idProduit={idProduit}
            changeStatus={changeStatus}
            onClickDeleteStatus={onClickDeleteStatus}
            onClickSaveDocument={onClickSaveDocument}
            onClickDeleteDocument={onClickDeleteDocument}
          />);
      case 'C':
        return (
          <Cession
            produit={produit}
            idProduit={idProduit}
            changeStatus={changeStatus}
            onClickDeleteStatus={onClickDeleteStatus}
            onClickSaveDocument={onClickSaveDocument}
            onClickDeleteDocument={onClickDeleteDocument}
          />);
      case 'D':
        return (
          <Dissolution
            produit={produit}
            idProduit={idProduit}
            changeStatus={changeStatus}
            onClickDeleteStatus={onClickDeleteStatus}
            onClickSaveDocument={onClickSaveDocument}
            onClickDeleteDocument={onClickDeleteDocument}
          />);
      case 'CHS':
        return (
          <ChangementStatut
            produit={produit}
            idProduit={idProduit}
            changeStatus={changeStatus}
            onClickDeleteStatus={onClickDeleteStatus}
            onClickSaveDocument={onClickSaveDocument}
            onClickDeleteDocument={onClickDeleteDocument}
          />);
      default:
        break;
    }
  };

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDrawer-paper': { width: '70wv', maxWidth: '90wv' },
      }}
    >
      <div style={{ position: 'relative', width: '100%' }}>
        <div style={{ position: 'sticky', top: 0, left: 0, right: 0, zIndex: 1000, padding: '20px', backgroundColor: '#fff', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', width: '100%' }}>
          <div className='flex w-full justify-between items-center'>
            <img src={app_logo} alt='logo' className='w-[300px]' />
            <IconButton onClick={onClose}>
              <CloseIcon sx={{ fontSize: 40 }} />
            </IconButton>
          </div>
        </div>
        <Box>
          <h2 className='font-poppins font-medium text-2xl ml-2 my-5 '>
            Détails du produit:</h2>
          {getContent(product_id.split('-')[0])}
        </Box>
      </div>
    </Drawer>
  );
}
