import React, { useState } from 'react';
import { useSpring } from '@react-spring/web';
import { Checkbox, Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, IconButton, Tooltip } from '@mui/material';
import { format } from 'date-fns';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import documentIcon from '../../../utils/assets/icons/documentIcon.png';
import productIdIcon from '../../../utils/assets/icons/productIdIcon.png';
import DocumentsBox from '../documentBox';
import getEnvironement from '../../../environnement';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { toast } from 'react-toastify';
function ProductsTable({ header, data, onClickDetails, onClickDeleteProduct, gestionnaires, changeStatus, getProducts, idClient, user }) {
  const [open, setOpen] = useState(false);
  const [activeProduct, setActiveProduct] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedGestionnaire, setSelectedGestionnaire] = useState('');
  const [filePathes, setFilePathes] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const [springs, api] = useSpring(() => ({ from: { transform: 'translateY(100%)' } }));




  const getStatus = (statusObj) => {
    if (!statusObj || !statusObj.status) return null;

    const statusStyles = {
      "En attente de validation": { bg: "#FFF3CD", text: "#856404" },
      "Rejetée": { bg: "#F8D7DA", text: "#721C24" },
      "Validée - En attente d'affectation": { bg: "#CCE5FF", text: "#004085" },
      "En cours de traitement": { bg: "#E2D9F3", text: "#533F8F" },
      "Attestation ICE fournie": { bg: "#D4EDDA", text: "#155724" },
      "Certificat Négatif fournie": { bg: "#D6E4F0", text: "#1B4F72" },
      "Docs prêts – En attente de légalisation": { bg: "#D1F2EB", text: "#117864" },
      "Finalisé": { bg: "#EDEDED", text: "#495057" },
    };

    const { bg, text } = statusStyles[statusObj.status] || { bg: "#E0E0E0", text: "#333" };

    return (
      <p className="px-3 py-2 rounded-full font-poppins text-sm text-center" style={{ backgroundColor: bg, color: text }}>
        {statusObj.status}
      </p>
    );
  };

  const extractFileNames = (products) => {
    return products.map(product => ({
      product_id: product.product_id,
      file_names: product.documents ? product.documents.map(doc => doc.file) : []
    }));
  };

  const getFilePathesByProductId = (productsData, productId) => {
    const product = productsData.find(p => p.product_id === productId);
    return product ? product.file_names.map(fileName => `${getEnvironement().BACKEND_URL}/storage/app/public/pdfs/${fileName}`) : [];
  };

  const handelClickDocuments = (productId) => {
    setActiveProduct(productId);
    api.start({ from: { transform: 'translateY(100%)' }, to: { transform: 'translateY(0)' }, config: { tension: 300, friction: 20 } });
    setFilePathes(getFilePathesByProductId(extractFileNames(data), productId));
    setOpen(true);
  };

  const handleSelectProduct = (productId) => {
    setSelectedProducts((prev) =>
      prev.includes(productId) ? prev.filter((id) => id !== productId) : [...prev, productId]
    );
  };

  const handleSelectAll = () => {
    const selectableProducts = data.filter((item) => item.status.id === 3).map((item) => item.id);
    setSelectedProducts((prevSelected) =>
      prevSelected.length === selectableProducts.length ? [] : selectableProducts
    );
  };


  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const assignToGestionnaire = async () => {
    if (!selectedGestionnaire || selectedProducts.length === 0) {
      toast.error('Veuillez sélectionner un gestionnaire et au moins un produit.');
      return;
    }
    console.log(selectedProducts);

    try {
      const response = await fetch(`${getEnvironement().API_URL}/admin/products/assign`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ product_ids: selectedProducts, gestionnaire_id: selectedGestionnaire }),
      });

      if (!response.ok) {
        throw new Error(`Erreur: ${response.statusText}`);
      }

      toast.success('Produits affectés avec succès');
      getProducts(idClient);
      closeModal();
    } catch (error) {
      toast.error('Échec de l\'affectation des produits');
      console.error('Erreur:', error);
    }
  };

  return (
    <>
      <DocumentsBox open={open} setOpen={setOpen} springs={springs} productId={activeProduct} filePathes={filePathes} />

      {user.role_id == 1 && (
        <div className='flex justify-end mb-4'>
          <Button
            variant='contained'
            color='primary'
            startIcon={<AssignmentIcon />}
            onClick={openModal}
            disabled={selectedProducts.length === 0}
          >
            Assigner à un gestionnaire
          </Button>
        </div>
      )}
      <Dialog open={modalOpen} onClose={closeModal}>
        <DialogTitle>Choisir un gestionnaire</DialogTitle>
        <DialogContent>
          <Select
            value={selectedGestionnaire}
            onChange={(e) => setSelectedGestionnaire(e.target.value)}
            fullWidth
            displayEmpty
            sx={{
              backgroundColor: "#fff",
              borderRadius: "8px",
              "& .MuiSelect-select": {
                padding: "10px",
              },
              "&:hover": {
                backgroundColor: "#f5f5f5",
              },
            }}
          >
            <MenuItem value="" disabled>
              Sélectionnez un gestionnaire
            </MenuItem>
            {gestionnaires.map((g) => (
              <MenuItem key={g.id} value={g.id}>
                {g.nom} {g.prenom}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal}>Annuler</Button>
          <Button onClick={assignToGestionnaire} variant="contained" color="primary">Assigner</Button>
        </DialogActions>
      </Dialog>
      <table className='table-auto w-full'>
        <thead>
          <tr className='border-solid border-b border-[#EBEBEB]'>
            <th className='flex items-center'>
              {data.filter((item) => item.status.id === 3).length > 0 && user.role_id == 1 && (
                <Checkbox
                  onChange={() => handleSelectAll()}
                  checked={selectedProducts.length > 0 && selectedProducts.length === data.filter((item) => item.status.id === 3).length}
                />
              )}
            </th>
            {header.map((item, index) => (
              <th key={item} className={`font-poppins font-medium text-sm/[16px] opacity-50 py-5 ${index === 0 && 'text-start w-24'}`}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index} className='border-solid border-b border-[#EBEBEB]'>
              <td>
                {item.status.id === 3 && user.role_id == 1 && (
                  <Checkbox
                    checked={selectedProducts.includes(item.id)}
                    onChange={() => handleSelectProduct(item.id)}
                  />
                )}
              </td>

              <td className='min-w-32 md:min-w-none flex py-2 items-center font-poppins font-normal text-sm/[16px]'>
                <img src={productIdIcon} />
                <p className='ml-3 max-w-14 break-words'>{item.product_id}</p>
              </td>
              <td className='font-poppins font-normal text-sm/[16px] text-center opacity-50'>{item.type}</td>
              <td className='font-poppins font-normal text-sm/[16px] text-center'>{format(new Date(item.created_at), 'yyyy-MM-dd HH:mm')}</td>
              <td>{getStatus(item.status)}</td>
              <td className='py-5 text-center'>
                <Tooltip title='Documents'>
                  <IconButton onClick={() => handelClickDocuments(item.product_id)}>
                    <img src={documentIcon} />
                  </IconButton>
                </Tooltip>
              </td>
              {user.role_id === 1 && (
                <td className='py-5 text-center'>
                  {item.gestionnaire !== null ? item.gestionnaire.nom + ' ' + item.gestionnaire.prenom : 'N / A'}

                </td>
              )}
              <td className="py-5 text-center">
                <div className="flex justify-center items-center gap-3">
                  <Tooltip title="Voir les détails" placement="top">
                    <IconButton onClick={() => onClickDetails(item.product_id)}>
                      <VisibilityIcon style={{ color: "#1976d2" }} />
                    </IconButton>
                  </Tooltip>
                  {item.status.id !== 8 && item.status.id !== 1 && (
                    <Tooltip title="Supprimer" placement="top">
                      <IconButton onClick={() => window.confirm("Êtes-vous sûr de vouloir supprimer ce produit ?") && onClickDeleteProduct(item.product_id)}>
                        <DeleteIcon style={{ color: "#d32f2f" }} />
                      </IconButton>
                    </Tooltip>
                  )}
                  {item.status.id === 1 && (
                    <>
                      <Tooltip title="Valider" placement="top">
                        <IconButton onClick={() => changeStatus(item.id, 3)}>
                          <CheckCircleIcon style={{ color: "green", transition: "0.2s" }} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Rejeter" placement="top">
                        <IconButton onClick={() => changeStatus(item.id, 2)}>
                          <CancelIcon style={{ color: "red", transition: "0.2s" }} />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

export default ProductsTable;
