import React from "react";

const GenerateContratMarketingPDF = ({ productData }) => {
    const extractDurationInMonths = () => {
        const item = productData?.pack?.items?.find(item => item.includes("mois de domiciliation"));
        if (item) {
            const match = item.match(/\d+/);
            return match ? parseInt(match[0], 10) : 0;
        }
        const offer = productData?.pack?.offer;
        if (offer) {
            const match = offer.match(/\d+/);
            if (match) {
                const value = parseInt(match[0], 10);
                return offer.includes("ans") ? value * 12 : value;
            }
        }
        return 0;
    };

    const calculateEndDate = (startDate, monthsToAdd) => {
        const date = new Date(startDate);
        date.setMonth(date.getMonth() + monthsToAdd);
        return date.toISOString().split("T")[0];
    };

    const durationInMonths = extractDurationInMonths();
    const formattedDate = productData?.created_at
        ? new Date(productData?.created_at).toISOString().split("T")[0]
        : "Date non spécifiée";
    const endDate = productData?.created_at
        ? calculateEndDate(productData?.created_at, durationInMonths)
        : "Date non spécifiée";

    return (
        <div className="pdf-container" style={{ fontFamily: "Arial, sans-serif", padding: '20mm', fontSize: "17px" }}>
            <div style={{ width: "100%", background: "white" }}>
                <div className="header" style={{ textAlign: "center", marginBottom: "20px" }}>
                    <h2 style={{ fontWeight: 'bold', textTransform: "uppercase" }}>
                        <u>CONTRAT DE PRESTATION DE SERVICES MARKETING</u>
                    </h2>
                </div>

                <div className="body pdf-content">
                    <b>Entre les soussignés :</b>
                    <p>
                        <strong>{productData?.denomination}</strong>, dont le siège social est situé à <strong>{productData?.siegeSociale}</strong>,
                        immatriculé(e) sous le numéro <strong>{productData?.ice}</strong>, représenté(e) par <strong>{productData?.nomBeneficiaire} {productData?.prenomBeneficiaire}</strong>,
                        ci-après dénommé(e) <strong>&quot;le Client&quot;</strong>.
                    </p>
                    <p>Et</p>
                    <p>
                        <strong>LEGALSTATION</strong>, dont le siège social est situé à <strong>56, Farhat Hachad, 3ème étage, Casablanca</strong>,
                        immatriculé(e) sous le numéro <strong>234567890567</strong>, représenté(e) par <strong>Mohamed Oukheyi</strong>,
                        ci-après dénommé(e) <strong>&quot;le Prestataire&quot;</strong>.
                    </p>
                    <br />
                    <h2 style={{ marginTop: '15px', fontWeight: "bold" }}><u>Article 1 - Objet du contrat</u></h2>
                    <p>
                        Le présent contrat définit les conditions dans lesquelles le Prestataire fournira des services
                        de marketing au Client, incluant la gestion de la communication digitale, la création de contenu,
                        ainsi que la stratégie publicitaire.
                    </p>

                    <h2 style={{ marginTop: '15px', fontWeight: "bold" }}><u>Article 2 - Durée du contrat</u></h2>
                    <p>
                        Le contrat prend effet à compter du <strong>{formattedDate}</strong> pour une durée de <strong>{durationInMonths} mois</strong>.
                    </p>
                    <br />
                    <h2 style={{ marginTop: '15px', fontWeight: "bold" }}><u>Article 3 - Obligations du Prestataire</u></h2>
                    <ul>
                        <li>Fournir les services définis avec professionnalisme.</li>
                        <li>Respecter les délais convenus.</li>
                        <li>Assurer une communication régulière avec le Client.</li>
                    </ul>

                    <h2 style={{ marginTop: '15px', fontWeight: "bold" }}><u>Article 4 - Obligations du Client</u></h2>
                    <ul>
                        <li>Fournir toutes les informations nécessaires au Prestataire.</li>
                        <li>Effectuer les paiements selon les modalités convenues.</li>
                        <li>Valider les livrables dans les délais impartis.</li>
                    </ul>

                    <br />
                    <h2 style={{ marginTop: '15px', fontWeight: "bold" }}><u>Article 5 - Rémunération et modalités de paiement</u></h2>
                    <p>
                        Le Client s'engage à verser au Prestataire la somme de <strong>{productData?.pack?.price} DH</strong>
                        selon les modalités suivantes : paiement mensuel ou en une seule fois selon accord.
                    </p>
                    <br />
                    <h2 style={{ marginTop: '15px', fontWeight: "bold" }}><u>Article 6 - Propriété intellectuelle</u></h2>
                    <p>
                        Les livrables produits dans le cadre de ce contrat restent la propriété du <strong>Client</strong>,
                        sauf mention contraire précisée dans un avenant spécifique.
                    </p>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    
                    <h2 style={{ marginTop: '15px', fontWeight: "bold" }}><u>Article 7 - Confidentialité</u></h2>
                    <p>
                        Les parties s'engagent à garder confidentielles toutes les informations échangées et à ne pas les divulguer
                        à des tiers sans l'accord préalable écrit de l'autre partie.
                    </p>
                    <br />
                    <h2 style={{ marginTop: '15px', fontWeight: "bold" }}><u>Article 8 - Résiliation</u></h2>
                    <p>
                        En cas de non-respect des obligations, le contrat pourra être résilié après une mise en demeure
                        restée sans effet pendant 10 jours.
                    </p>
                    <br />
                    <h2 style={{ marginTop: '15px', fontWeight: "bold" }}><u>Article 9 - Litiges</u></h2>
                    <p>
                        En cas de litige, les parties s'engagent à rechercher une solution amiable.
                        À défaut, le litige sera soumis aux tribunaux compétents conformément à la législation en vigueur.
                    </p>

                    <div className="footer pdf-content">


                        <p style={{ marginTop: "20px", textAlign: 'center', fontWeight: 700 }}>
                            À Casablanca, le {formattedDate}
                        </p>
                        <table
                            style={{
                                width: "100%",
                                marginTop: "60px",
                                textAlign: "center",
                                borderCollapse: "collapse",
                            }}
                        >
                            <thead>
                                <tr>
                                    <th style={{ border: "1px solid black", padding: "10px", width: "50%" }}>SIGNATURE PRESTATAIRE</th>
                                    <th style={{ border: "1px solid black", padding: "10px", width: "50%" }}>SIGNATURE CLIENT</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ height: "200px", border: "1px solid black" }}></td>
                                    <td style={{ border: "1px solid black" }}></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GenerateContratMarketingPDF;
